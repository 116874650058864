import { DataItemProperties } from '@cp/base-types';
import * as _ from 'lodash';
export * from './flatten';
export * from './constants';
export * from './endpoints';
export const isDefinedAndNotEmpty = (value) => {
    if (value === undefined || value === null) {
        return false;
    }
    return !(value && typeof value === 'object' && Object.keys(value).length === 0);
};
export function isVirtualPropertyKey(key) {
    return key.startsWith('__') || key === '@context';
}
export function removeVirtualProperties(obj) {
    _.forOwn(obj, (value, key) => {
        if (isVirtualPropertyKey(key)) {
            delete obj[key];
        }
        else if (typeof value === 'object' && value !== null) {
            removeVirtualProperties(value);
        }
    });
    return obj;
}
export function sortItemsByIdentifiers(items, identifiers) {
    return _.sortBy(items, (item) => {
        return item.identifier ? identifiers.indexOf(item.identifier) : -1;
    });
}
export const clearNulls = (obj) => {
    if (obj && typeof obj === 'object') {
        for (const key of Object.keys(obj)) {
            if ((obj[key] === null || obj[key] === undefined) && !Array.isArray(obj)) {
                _.unset(obj, key);
            }
            else if (typeof obj[key] === 'object') {
                clearNulls(obj[key]);
            }
        }
    }
    return obj;
};
export function areDataItemsEqual(dataA, dataB) {
    if (!dataA || !dataB || typeof dataA !== 'object' || typeof dataB !== 'object') {
        return false;
    }
    const dataACopy = _.cloneDeep(dataA);
    const dataBCopy = _.cloneDeep(dataB);
    const processing = (d) => {
        for (const [key, value] of Object.entries(d)) {
            if ((!!value && typeof value === 'object' && Object.keys(value).length === 0) || value === null || value === undefined) {
                delete d[key];
            }
            else if (!!value && typeof value === 'object' && Object.keys(value).length > 0) {
                processing(d[key]);
                if (Object.keys(value).length === 0) {
                    delete d[key];
                }
            }
        }
    };
    processing(dataACopy);
    processing(dataBCopy);
    return _.isEqual(dataACopy, dataBCopy);
}
function isObjectWithType(o) {
    return _.isObject(o) && _.has(o, '_type') && typeof _.get(o, '_type') === 'string';
}
export const localizationMergingCustomizer = (baseValue, localizationValue) => {
    if (_.isArray(baseValue) && _.isArray(localizationValue)) {
        if (baseValue.every((baseItem) => ['string', 'number', 'boolean'].includes(typeof baseItem)) &&
            localizationValue.every((localizationItem) => ['string', 'number', 'boolean'].includes(typeof localizationItem)) &&
            localizationValue.length > 0) {
            return localizationValue;
        }
        const updatedArray = [...baseValue];
        const mismatchedLocalizations = [];
        const mismatchedIndexesMap = {};
        localizationValue.forEach((localizationValue, index) => {
            if (localizationValue === null || localizationValue === undefined) {
                return;
            }
            if (isObjectWithType(localizationValue) && isObjectWithType(updatedArray[index]) && updatedArray[index]._type !== localizationValue._type) {
                mismatchedLocalizations.push(localizationValue);
                if (index in updatedArray) {
                    if (!mismatchedIndexesMap[updatedArray[index]._type]) {
                        mismatchedIndexesMap[updatedArray[index]._type] = [];
                    }
                    mismatchedIndexesMap[updatedArray[index]._type].push(index);
                }
                return;
            }
            if (updatedArray[index] && (_.isArray(updatedArray[index]) || _.isObjectLike(updatedArray[index]))) {
                updatedArray[index] = _.mergeWith(_.isArray(updatedArray[index]) ? [] : {}, updatedArray[index], localizationValue, localizationMergingCustomizer);
            }
            else if (isObjectWithType(localizationValue)) {
                mismatchedLocalizations.push(localizationValue);
            }
            else {
                updatedArray[index] = localizationValue;
            }
        });
        if (localizationValue.length < updatedArray.length) {
            updatedArray.slice(localizationValue.length).forEach((value, index) => {
                if (isObjectWithType(value)) {
                    if (!mismatchedIndexesMap[value._type]) {
                        mismatchedIndexesMap[value._type] = [];
                    }
                    mismatchedIndexesMap[value._type].push(index + localizationValue.length);
                }
            });
        }
        for (const mismatchedLocalization of mismatchedLocalizations) {
            if (!(mismatchedLocalization._type in mismatchedIndexesMap)) {
                continue;
            }
            const index = mismatchedIndexesMap[mismatchedLocalization._type].shift();
            if (index === undefined) {
                continue;
            }
            updatedArray[index] = _.mergeWith({}, updatedArray[index], mismatchedLocalization, localizationMergingCustomizer);
        }
        return updatedArray;
    }
};
export function fillEmptyArraysItems(obj, onlyEmpty, replaceWithNull) {
    if (!obj) {
        return;
    }
    if (Array.isArray(obj)) {
        const arr = obj;
        for (let i = 0; i < arr.length; i++) {
            if (onlyEmpty ? typeof arr[i] === 'undefined' : !arr[i]) {
                arr[i] = replaceWithNull ? null : {};
            }
            else {
                fillEmptyArraysItems(arr[i], onlyEmpty, replaceWithNull);
            }
        }
    }
    else if (typeof obj === 'object') {
        Object.keys(obj).forEach((key) => {
            fillEmptyArraysItems(obj[key], onlyEmpty, replaceWithNull);
        });
    }
}
export const pickObjectProperties = (obj, properties) => {
    const result = {};
    for (const property of properties) {
        const path = _.toPath(property);
        const prop = path.shift();
        if (!prop)
            continue;
        const value = _.get(obj, prop);
        if (value == null)
            continue;
        if (!path.length) {
            result[prop] = value;
            continue;
        }
        if (Array.isArray(value)) {
            const results = [];
            for (const item of value) {
                const itemResult = pickObjectProperties(item, [path.join('.')]);
                if (Array.isArray(itemResult) && itemResult.length > 0) {
                    results.push(...itemResult);
                }
                else if (!Array.isArray(itemResult) && Object.keys(itemResult).length > 0) {
                    results.push(itemResult);
                }
            }
            if (results.length > 0) {
                result[prop] = results;
            }
        }
        const next = pickObjectProperties(value, [path.join('.')]);
        if (Object.keys(next).length > 0) {
            result[prop] = next;
        }
    }
    return result;
};
export function processObjectKeyDeep(obj, targetKey, callback) {
    if (!(obj instanceof Object))
        return;
    if (Array.isArray(obj))
        return obj.forEach((item) => processObjectKeyDeep(item, targetKey, callback));
    Object.entries(obj).forEach(([key, value]) => {
        if (key === targetKey) {
            obj[key] = callback(value);
        }
        else {
            processObjectKeyDeep(value, targetKey, callback);
        }
    });
}
export function removeKeyFromObjectDeep(obj, targetKey) {
    if (typeof obj !== 'object' || obj === null || Object.keys(obj).length === 0)
        return obj;
    if (Array.isArray(obj))
        return obj.map((item) => removeKeyFromObjectDeep(item, targetKey));
    return Object.fromEntries(Object.entries(obj)
        .filter(([key]) => key !== targetKey)
        .map(([key, value]) => [key, removeKeyFromObjectDeep(value, targetKey)]));
}
export function diffObjects(obj1, obj2) {
    if (typeof obj1 !== typeof obj2 || obj1 === null || obj2 === null || obj1 === undefined || obj2 === undefined) {
        return obj2;
    }
    if (typeof obj1 === 'object' && typeof obj2 === 'object') {
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) {
                return obj2;
            }
            else {
                const diffArray = obj1
                    .map((val1, index) => {
                    const val2 = obj2[index];
                    return diffObjects(val1, val2);
                })
                    .filter((item) => Object.keys(item).length > 0);
                return diffArray.length > 0 ? diffArray : {};
            }
        }
        const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
        const diff = {};
        keys.forEach((key) => {
            const val1 = obj1[key];
            const val2 = obj2[key];
            if (!(key in obj2)) {
                diff[key] = '__removed';
            }
            else {
                const diffVal = diffObjects(val1, val2);
                if (diffVal !== undefined && (typeof diffVal !== 'object' || Object.keys(diffVal).length > 0)) {
                    diff[key] = diffVal;
                }
            }
        });
        return Object.keys(diff).length > 0 ? diff : {};
    }
    return obj1 === obj2 ? {} : obj2;
}
export function extractRegExpText(regExp) {
    return regExp.source.replace(/\\x2d/g, '-').replace(/\\(.)/g, '$1');
}
export const deepCompare = (source, target) => {
    if (source === target) {
        return true;
    }
    if (typeof source !== typeof target) {
        return false;
    }
    if (Array.isArray(source) && Array.isArray(target)) {
        if (source.length !== target.length) {
            return false;
        }
        for (let i = 0; i < source.length; i++) {
            if (!deepCompare(source[i], target[i])) {
                return false;
            }
        }
        return true;
    }
    if (typeof source === 'object' && source !== null && typeof target === 'object' && target !== null) {
        const keysA = Object.keys(source);
        for (const key of keysA) {
            if (!(key in target) || !deepCompare(source[key], target[key])) {
                return false;
            }
        }
        return true;
    }
    return false;
};
export async function aggregateWithOptions(args) {
    if (args.tryToUseFullTextIndex) {
        const fulltextPipeline = convertPipelineToFullText(args.aggregation);
        try {
            return (await args.collection.aggregate(fulltextPipeline, args.aggregateOptions).toArray());
        }
        catch (e) {
            if (e.code === 27 || e.code === 40074 || e.code === 17313 || e.code === 100 || e.code === 173) {
                console.warn('Failed to execute fulltext aggregation, trying to execute without fulltext', e);
                return (await args.collection.aggregate(args.aggregation, args.aggregateOptions).toArray());
            }
            throw e;
        }
    }
    else {
        return await args.collection.aggregate(args.aggregation, args.aggregateOptions).toArray();
    }
}
function convertPipelineToFullText(obj) {
    if (Array.isArray(obj)) {
        return obj.map(convertPipelineToFullText);
    }
    if (typeof obj === 'object' && obj !== null) {
        const newObj = {};
        for (const key in obj) {
            const value = obj[key];
            if (key === DataItemProperties.SEARCH_TEXT_KEY && typeof value === 'object' && value !== null && '$regex' in value) {
                newObj['$text'] = { $search: value.$regex };
            }
            else {
                newObj[key] = convertPipelineToFullText(obj[key]);
            }
        }
        return newObj;
    }
    return obj;
}
