import { Checkbox } from '@fluentui/react';
import { WidgetProps } from '@rjsf/core';
import * as _ from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';
import { FormContext, PathContext } from '@cpa/base-core/constants/context';

import DescriptionField from '../DescriptionField/DescriptionField';

import styles from './CheckboxWidget.module.scss';

// Keys of ICheckboxProps from @fluentui/react
const allowedProps = [
  'componentRef',
  'className',
  'checked',
  'defaultChecked',
  'label',
  'disabled',
  'required',
  'onChange',
  'title',
  'id',
  'name',
  'inputProps',
  'boxSide',
  'theme',
  'ariaLabel',
  'ariaLabelledBy',
  'ariaDescribedBy',
  'ariaPositionInSet',
  'ariaSetSize',
  'styles',
  'onRenderLabel',
  'checkmarkIconProps',
  'indeterminate',
  'defaultIndeterminate',
];

const checkboxStyles = {
  label: {
    userSelect: 'text',
  },
};

const CheckboxWidget = ({ id, schema: baseSchema, options, value, required, disabled, readonly, label, onChange }: WidgetProps): JSX.Element => {
  const pathContext = useContext(PathContext);
  const formContext = useContext(FormContext);

  const schemaOverwrite = formContext.schemaOverwrites[pathContext];

  const schema = useMemo(() => {
    if (!schemaOverwrite) {
      return baseSchema;
    }

    return _.merge({}, baseSchema, schemaOverwrite);
  }, [baseSchema, schemaOverwrite]);

  const hideDescriptions = useSelector((state: IGlobalState) => state.settings.hideDescriptionsInForm);
  const _onChange = useCallback(
    (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      onChange(checked);
    },
    [onChange]
  );

  const uiProps = useMemo(() => _.pick(options.props || {}, allowedProps) as object, [options.props]);

  return (
    <>
      <Checkbox
        id={id}
        className={classNames(styles.checkbox, {
          [styles.checkboxWithDescription]: !hideDescriptions,
          [styles.readOnlyChecked]: (readonly || schema.readOnly) && value,
          [styles.readOnlyUnchecked]: (readonly || schema.readOnly) && !value,
          [styles.checkboxRequired]: required,
        })}
        onChange={readonly ? undefined : _onChange}
        label={label || schema.title}
        required={required}
        disabled={disabled || schema.readOnly}
        checked={value}
        boxSide={'end'}
        styles={checkboxStyles}
        {...uiProps}
      />
      <DescriptionField description={schema.description || ''} detailed />
    </>
  );
};

export default CheckboxWidget;
