import {
  IJSONSchema,
  OnAfterFormCloseExecutionContext,
  OnAfterFormSubmitExecutionContext,
  OnBeforeFormCloseExecutionContext,
  OnBeforeFormSubmitExecutionContext,
  Schemas,
  TypeConstants,
  TypeTrigger,
} from '@cp/base-types';
import { areDataItemsEqual, cloneDeepWithMetadata, DataServiceModules } from '@cp/base-utils';
import { axiosDictionary, postEntityToEndpoint } from '@cpa/base-core/api';
import { FormatSource, ItemInfoContext } from '@cpa/base-core/constants';
import {
  cleanupRecursive,
  copyToClipboardAsync,
  executeUiTriggers,
  getAppBasePath,
  replaceAnyThingWithSchema,
  resolveUiTriggersCode,
  showDialog,
} from '@cpa/base-core/helpers';
import { getUserJwtDetails } from '@cpa/base-core/helpers/jwt-details';
import { ActionType, useCustomCommandBarButtons, useIsCached, useItemModificationSubscription, usePowerUser } from '@cpa/base-core/hooks';
import { useFormTimestamp } from '@cpa/base-core/hooks/form';
import { IGlobalState } from '@cpa/base-core/store';
import { applySettings, setExpertMode } from '@cpa/base-core/store/settings/actions';
import { ICustomAction, IDataItem, IGenericComponentData } from '@cpa/base-core/types';
import {
  DefaultButton,
  DialogType,
  IconButton,
  IContextualMenuProps,
  IScrollablePane,
  MessageBar,
  MessageBarType,
  PanelBase,
  ScrollablePane,
  Sticky,
  StickyPositionType,
  Text,
  ThemeContext,
} from '@fluentui/react';
import { useBoolean, useId, useSetTimeout } from '@fluentui/react-hooks';
import { IChangeEvent } from '@rjsf/core';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import urlJoin from 'url-join';

import Drawer from '../Drawer/Drawer';
import Form, { IFormChangeEvent } from '../Form/Form';
import HoverTooltip from '../HoverTooltip/HoverTooltip';
import HtmlContent from '../HtmlContent/HtmlContent';
import { LayoutContext } from '../Layout/Layout';
import MessageBars from '../MessageBars/MessageBars';
import ReadonlyContent from '../ReadonlyContent/ReadonlyContent';
import { IWizardOptions } from '../ScrollingContent/ScrollingContent';

import InputLanguage from './components/InputLanguage/InputLanguage';
import styles from './ItemInfo.module.scss';

export interface IItemInfoProps {
  isOpened: boolean;
  onClose: () => void;
  data: IGenericComponentData;
  renderBefore?: JSX.Element;
  onActionClick?: (action: ActionType | string, payload?: unknown, event?: React.MouseEvent<HTMLDivElement>) => void;
  customActions?: ICustomAction[];

  // flag for displaying readonly content instead of form
  readonly: boolean;

  // passing null for triggering add action
  item: IDataItem | null;

  // type for operation for desiring right handler (onAdd/onEdit) inside onSubmit
  type: 'add' | 'edit';
  onAdd?: (item: IDataItem) => void | Promise<void>;
  onEdit?: (item: IDataItem) => void | Promise<void>;
  onChange?: (event: IFormChangeEvent) => void;
  onSubmitAs?: (event: IDataItem) => void | Promise<void>;

  // Object will be merged before onAdd/onEdit calling
  prefill?: IDataItem;
  wizardOptions?: IWizardOptions;
}

const containsExpertField = (properties: IJSONSchema['properties'] | undefined): boolean => {
  if (!properties) return false;
  return _.some(properties, (property) => {
    if (property.properties) return containsExpertField(property.properties);
    return property.expertMode === true;
  });
};

const ItemInfo: React.FC<IItemInfoProps> = ({
  isOpened,
  onClose,
  data,
  renderBefore,
  readonly,
  item,
  onActionClick,
  customActions,
  onAdd,
  onEdit,
  onChange,
  onSubmitAs,
  prefill,
  type,
  wizardOptions,
}) => {
  const fullscreen = !!data.page?.fullPageDrawer || !!(wizardOptions && wizardOptions.isFullScreen);

  const id = useId('drawer');

  const theme = useContext(ThemeContext);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const expertMode = useSelector((state: IGlobalState) => state.settings.expertMode);
  const headerLogo = useSelector((state: IGlobalState) => state.app.cpa?.headerLogo);
  const powerUser = usePowerUser(undefined, data.page.cpTypeUrl);
  const [t, i18n] = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const isCached = useIsCached();
  const [openTimeStamp, resetTimestamp] = useFormTimestamp(isOpened);

  const [errors, setErrors] = useState<string[]>([]);
  const [formMessages, setFormMessages] = useState<{ [key: string]: MessageBarType }>({});

  const trackedFormData = useRef<IDataItem | null | undefined>(item);
  const trackedInitialFormData = useRef<IDataItem | null | undefined>(item);
  const formInitialized = useRef<boolean>(false);
  const [submitDisabled, { setTrue: disableSubmit, setFalse: enableSubmit }] = useBoolean(false);
  const { setTimeout } = useSetTimeout();
  const [isCopiedToClipboard, { setTrue: setCopied, setFalse: unsetCopied }] = useBoolean(false);
  const hideDescriptionsInForm = useSelector((state: IGlobalState) => state.settings.hideDescriptionsInForm);
  const editFormsAsJson = useSelector((state: IGlobalState) => state.settings.editFormsAsJson);
  const [resolvedSchema, setResolvedSchema] = useState<IJSONSchema | null>(null);
  const [canUseJsonEditor, setCanUseJsonEditor] = useState(false);
  const allLocales = useSelector((state: IGlobalState) => state.app.allLocales);

  const scrollablePaneRef = useRef<IScrollablePane & { contentContainer: HTMLElement | null }>(null);

  const showMessage = useCallback((text: string, _type: MessageBarType = MessageBarType.info) => {
    setFormMessages((prevFormMessages) => ({ ...prevFormMessages, [text]: _type }));
  }, []);

  const dismissMessage = useCallback((text: string) => {
    setFormMessages((prevFormMessages) => {
      return _.omit(prevFormMessages, text);
    });
  }, []);

  // Handler for fluentui (expects callback wich returns handler function)
  const dissmissMessageHandler = useCallback((text: string) => () => dismissMessage(text), [dismissMessage]);

  useEffect(() => {
    let mounted = true;

    const loadUserJwtDetails = async (): Promise<void> => {
      const details = await getUserJwtDetails();

      const rolesToCheck = ['group:cosmoconsult.com:G-T-CP-Contributors', 'group:cp-licensing-development'];
      if (details?.derivedRoles?.some((role) => rolesToCheck.includes(role))) {
        setCanUseJsonEditor(true);
      }
    };

    const getResolvedSchema = async (): Promise<void> => {
      if (!mounted) {
        return;
      }

      if (data.schema && item && isOpened) {
        setResolvedSchema(await replaceAnyThingWithSchema(data.schema, item));
      } else {
        // We want to reset resolved schema when form is closed
        // Otherwise old resolved schema will be used during next form initialization
        setResolvedSchema(null);
      }
    };

    loadUserJwtDetails();
    getResolvedSchema();

    return () => {
      mounted = false;
    };
  }, [data.schema, isOpened, item]);

  const isExpertFieldInside = useMemo(() => {
    return containsExpertField(data.schema?.properties);
  }, [data.schema]);

  useEffect(() => {
    trackedFormData.current = item;
    trackedInitialFormData.current = item;
  }, [item]);

  useEffect(() => {
    formInitialized.current = false;

    // Give time for form to initialize
    // TODO: Change if found a better way with react-json-schema-form
    setTimeout(() => {
      formInitialized.current = true;
      enableSubmit();
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, item]);

  const handleCopiedToClipboard = useCallback(
    async (formDataToShare: IDataItem<unknown>) => {
      try {
        const textPromise = async () => {
          // Create shared item
          const sharedItem = await postEntityToEndpoint<Schemas.CpSharedItem & IDataItem>(
            axiosDictionary.appDataService,
            `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpSharedItem)}`,
            {
              itemData: formDataToShare,
            }
          );

          // Generate url based on shared item
          const uri: string = urlJoin(
            `${window.location.origin}${getAppBasePath()}`,
            `${data.page?.path?.replace('/:id', '') || '/'}?action=add&sharedItem=${encodeURIComponent(sharedItem.identifier?.toString() || '-')}`
          );
          if (!uri) {
            unsetCopied();
            setErrors([t('errors.server')]);
            return new Blob([''], { type: 'text/plain' });
          }
          return new Blob([uri], { type: 'text/plain' });
        };
        copyToClipboardAsync(textPromise, () => {
          setCopied();
          setErrors([]);
        });
      } catch (e) {
        console.error(`Failed to generated shared item`, e);
      }
    },
    [data.page?.path, unsetCopied, t, setCopied]
  );

  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent): string | void => {
      if (!areDataItemsEqual(trackedInitialFormData.current, trackedFormData.current) && isOpened) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);
    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [isOpened]);

  useEffect(() => {
    const fullscreenDrawerWheelHandler = (event: React.WheelEvent<PanelBase>) => {
      event?.stopPropagation();
    };

    const scrollablePaneContainer = scrollablePaneRef.current?.contentContainer;

    // @ts-ignore
    scrollablePaneContainer?.addEventListener('wheel', fullscreenDrawerWheelHandler);

    return () => {
      // @ts-ignore
      scrollablePaneContainer?.removeEventListener('wheel', fullscreenDrawerWheelHandler);
    };
  }, [scrollablePaneRef, isOpened]);

  const onCloseDrawer = useCallback(
    async (submitted: boolean = false, submittedFormData?: IDataItem): Promise<boolean> => {
      const clonedFormData = cloneDeepWithMetadata(submittedFormData || trackedFormData.current || {});
      const schemaForTrigger = cloneDeepWithMetadata(data.schema || {});

      if (data.schema) {
        const closeState = { closing: true };

        const beforeCloseTriggers = data.schema.cp_typeTriggers?.[TypeTrigger.OnBeforeFormClose];
        if (Array.isArray(beforeCloseTriggers) && beforeCloseTriggers.length) {
          await executeUiTriggers<OnBeforeFormCloseExecutionContext>(
            {
              event: TypeTrigger.OnBeforeFormClose,
              data: clonedFormData,
              schema: schemaForTrigger,
              closeState,
              submitted,
              page: data.page,
              isCreate: type === 'add',
              isUpdate: type === 'edit',
              showMessage,
            },
            await resolveUiTriggersCode(beforeCloseTriggers)
          );
        }

        if (!closeState.closing && !submitted) {
          return false;
        }
      }

      onClose();

      if (data.schema) {
        const afterCloseTriggers = data.schema.cp_typeTriggers?.[TypeTrigger.OnAfterFormClose];
        if (Array.isArray(afterCloseTriggers) && afterCloseTriggers.length) {
          await executeUiTriggers<OnAfterFormCloseExecutionContext>(
            {
              event: TypeTrigger.OnAfterFormClose,
              data: clonedFormData,
              schema: schemaForTrigger,
              submitted,
              page: data.page,
              isCreate: type === 'add',
              isUpdate: type === 'edit',
              showMessage,
            },
            await resolveUiTriggersCode(afterCloseTriggers)
          );
        }
      }
      return true;
    },
    [type, data.page, data.schema, onClose, showMessage]
  );

  const initialFormData = useMemo(() => {
    return _.merge({}, prefill || {}, item);
  }, [item, prefill]);

  const { refreshSuggestionVisible, setRefreshSuggestionVisible } = useItemModificationSubscription(
    data.page.cpTypeUrl,
    initialFormData.identifier,
    openTimeStamp
  );

  const onCloseAndClearErrors = useCallback(
    async (options?: { skipConfirmation?: boolean }) => {
      if (!!item && !!trackedFormData.current && !areDataItemsEqual(trackedInitialFormData.current, trackedFormData.current)) {
        let closeConfirmed: boolean = false;
        if (!options?.skipConfirmation) {
          // Show confirmation popup
          closeConfirmed = (await showDialog({
            message: '',
            title: t('common.beforeClose'),
            dialogContentProps: {
              type: DialogType.largeHeader,
              title: t('common.beforeClose'),
              subText: t('common.beforeCloseSubtitle'),
            },
            primaryButtonText: 'common.close',
            secondaryButtonText: 'common.cancel',
            closeOnClickOutside: true,
            closeOnAction: true,
          })) as boolean;
        }

        // If confirm skipped or confirmed
        if (options?.skipConfirmation || closeConfirmed === true) {
          setErrors([]);
          setFormMessages({});
          setRefreshSuggestionVisible(false);
          unsetCopied();
          await onCloseDrawer();
        } else {
          unsetCopied();
        }
      } else {
        setErrors([]);
        setFormMessages({});
        setRefreshSuggestionVisible(false);
        unsetCopied();
        await onCloseDrawer();
      }
    },
    [item, t, setRefreshSuggestionVisible, unsetCopied, onCloseDrawer]
  );

  const closeForm = useCallback(
    async (options: { skipConfirmation?: boolean }) => {
      await onCloseAndClearErrors(options);
    },
    [onCloseAndClearErrors]
  );

  const onBeforeSubmit: (obj: { formData: unknown }) => Promise<{ cancelSubmit: boolean }> = useCallback(
    async ({ formData }) => {
      // Exec triggers of type 'On Before Form Submit'
      let cancelSubmit = false;
      const beforeSubmitTriggers = data.schema?.cp_typeTriggers?.[TypeTrigger.OnBeforeFormSubmit];
      if (Array.isArray(beforeSubmitTriggers) && beforeSubmitTriggers.length && formData) {
        await executeUiTriggers<OnBeforeFormSubmitExecutionContext>(
          {
            event: TypeTrigger.OnBeforeFormSubmit,
            formData: formData as object,
            previousFormData: item ?? {},
            schema: data.schema as IJSONSchema,
            page: data.page,
            cancelSubmit: () => {
              cancelSubmit = true;
            },
            showMessage,
            dismissMessage,
          },
          await resolveUiTriggersCode(beforeSubmitTriggers)
        );
      }

      return { cancelSubmit };
    },
    [data.schema, data.page, showMessage, dismissMessage, item]
  );

  const onSubmit = useCallback(
    async ({ formData }: IChangeEvent, closeDrawer: boolean = true) => {
      disableSubmit();
      resetTimestamp();
      try {
        if (type === 'add') {
          await onAdd?.(formData);
        } else if (type === 'edit') {
          await onEdit?.(formData);
        }
        resetTimestamp();
        setErrors([]);
        setFormMessages({});
        unsetCopied();

        let formClosed: boolean = false;
        if (closeDrawer) {
          formClosed = await onCloseDrawer(true, formData as IDataItem);
        } else {
          enableSubmit();
        }

        const afterSubmitTriggers = data.schema?.cp_typeTriggers?.[TypeTrigger.OnAfterFormSubmit];
        if (Array.isArray(afterSubmitTriggers) && afterSubmitTriggers.length) {
          await executeUiTriggers<OnAfterFormSubmitExecutionContext>(
            {
              event: TypeTrigger.OnAfterFormSubmit,
              formData: formData,
              schema: cloneDeepWithMetadata(data.schema || {}),
              page: data.page,
              isCreate: type === 'add',
              isUpdate: type === 'edit',
              showMessage,
              formClosed,
            },
            await resolveUiTriggersCode(afterSubmitTriggers)
          );
        }
      } catch (e) {
        enableSubmit();
        setErrors([e.message ?? e.err]);
      }
    },
    [disableSubmit, type, unsetCopied, data.schema, data.page, onAdd, onEdit, showMessage, onCloseDrawer, enableSubmit, resetTimestamp]
  );

  const handleSubmitAs = useCallback(
    async ({ formData }: IChangeEvent) => {
      disableSubmit();
      try {
        await onSubmitAs?.(formData);
        setErrors([]);
        setFormMessages({});
        unsetCopied();
        await onCloseDrawer(true, formData as IDataItem);
      } catch (e) {
        enableSubmit();
        setErrors([e.message ?? e.err]);
      }
    },
    [disableSubmit, onSubmitAs, unsetCopied, onCloseDrawer, enableSubmit]
  );

  const onChangeHandler = useCallback(
    (obj: IFormChangeEvent) => {
      trackedFormData.current = obj.formData;

      if (!formInitialized.current) {
        trackedInitialFormData.current = obj.formData;
        formInitialized.current = true;
      }

      onChange?.(obj);
    },
    [onChange]
  );

  const getCurrentFormState = useCallback(() => {
    return trackedFormData.current;
  }, []);

  const readonlyContentOptions = useMemo(
    () => ({
      source: FormatSource.Drawer,
      expandFunctionalityOptions: {
        defaultExpanded: true,
      },
    }),
    []
  );

  const readonlyContentItem = useMemo(() => {
    const clonedItem = cloneDeepWithMetadata(item || {});
    cleanupRecursive(clonedItem);
    return clonedItem;
  }, [item]);

  const readonlyContent = useMemo(() => {
    return readonly ? (
      <div className={classNames(styles.readonly, { [styles.fullScreen]: fullscreen })}>
        <ReadonlyContent item={readonlyContentItem} data={data} options={readonlyContentOptions} />
      </div>
    ) : null;
  }, [readonly, fullscreen, readonlyContentItem, data, readonlyContentOptions]);

  const scrollablePaneStyles = useMemo(() => {
    return {
      contentContainer: {
        overflowX: 'hidden',
      },
    };
  }, []);

  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);

  const baseLanguage = useMemo(() => {
    let culture = item?._baseLanguage;
    if (!culture && type === 'add' && !readonly) {
      if (dataLanguage && dataLanguage !== 'notSelected') {
        culture = dataLanguage;
      } else {
        culture = i18n.language;
      }
    }

    if (!culture) {
      if (type === 'add' && !readonly && dataLanguage) {
        culture = dataLanguage;
      }
    }

    const locale = culture?.slice(0, 2).toUpperCase() || null;
    const icon = allLocales.find(({ identifier }) => identifier === culture)?.icon;
    return { locale, icon };
  }, [allLocales, dataLanguage, i18n.language, item?._baseLanguage, readonly, type]);

  const currentLanguage = useMemo(() => {
    let culture = i18n.language;
    if (dataLanguage && dataLanguage !== 'notSelected') {
      culture = dataLanguage;
    }

    const locale = culture.slice(0, 2).toUpperCase();
    const icon = allLocales.find(({ identifier }) => identifier === culture)?.icon;
    return { locale, icon };
  }, [allLocales, dataLanguage, i18n.language]);

  const itemInfoContextValue = useMemo(
    () => ({
      showMessage,
      dismissMessage,
      type,
      baseLanguage: baseLanguage.locale,
      currentLanguage: currentLanguage.locale,
    }),
    [baseLanguage, currentLanguage, showMessage, dismissMessage, type]
  );

  // FluentUI doesn't pass updated buttons to underlying OverflowSet for some reason.
  const latestOnActionClick = useRef(onActionClick!);
  latestOnActionClick.current = onActionClick!;

  const customCommandBarButtons = useCustomCommandBarButtons(latestOnActionClick, darkMode ? styles.controlDark : styles.control, customActions);

  const menuProps: IContextualMenuProps = useMemo(() => {
    const items: IContextualMenuProps['items'] = [];

    for (const button of customCommandBarButtons) {
      items.push(button);
    }

    return { items };
  }, [customCommandBarButtons]);

  const customFormButtons: JSX.Element[] = useMemo(() => {
    return customCommandBarButtons.length
      ? [
          <DefaultButton key={'Form__customActionsButton'} menuProps={menuProps}>
            {t('common.actions')}
          </DefaultButton>,
        ]
      : [];
  }, [customCommandBarButtons, menuProps, t]);

  const form = useMemo(
    () =>
      !readonly && data.schema ? (
        <div
          data-testid="Drawer"
          className={classNames(styles.formRoot, {
            [styles.fullScreen]: fullscreen,
            [styles.horizontalMenu]: layoutContext?.isHorizontalMenu,
            [styles.jsonMode]: editFormsAsJson,
          })}
        >
          <ScrollablePane styles={scrollablePaneStyles} componentRef={scrollablePaneRef}>
            <Sticky stickyPosition={StickyPositionType.Header}>
              <MessageBars className={styles.errorBars} messages={errors} messageBarType={MessageBarType.error} />
              {refreshSuggestionVisible && <MessageBar messageBarType={MessageBarType.info}>{t('common.itemRefreshSuggestion')}</MessageBar>}
              <>
                {Object.entries(formMessages).map(([message, _type]) => (
                  <MessageBar key={message} messageBarType={_type} onDismiss={dissmissMessageHandler(message)}>
                    <HtmlContent html={message} darkMode={false} />
                  </MessageBar>
                ))}
              </>
              {isCopiedToClipboard && <MessageBar onDismiss={unsetCopied}>{t('common.copiedToClipboard')}</MessageBar>}
            </Sticky>
            <ItemInfoContext.Provider value={itemInfoContextValue}>
              {renderBefore && renderBefore}
              {isOpened && (
                <Form
                  schema={resolvedSchema || data.schema}
                  formData={initialFormData}
                  onBeforeSubmit={onBeforeSubmit}
                  onSubmit={onSubmit}
                  onSubmitAs={handleSubmitAs}
                  closeForm={closeForm}
                  btnText={t('common.submit')}
                  readonly={submitDisabled}
                  onChange={onChangeHandler}
                  onFieldLocalizationChanged={resetTimestamp}
                  getCurrentFormState={getCurrentFormState}
                  showSaveUrl={true}
                  editFormsAsJson={editFormsAsJson}
                  onCopiedToClipboard={handleCopiedToClipboard}
                  page={data.page}
                  enableKeyboardHotkeys={true}
                  wizardOptions={wizardOptions}
                  customButtons={customFormButtons}
                />
              )}
            </ItemInfoContext.Provider>
          </ScrollablePane>
        </div>
      ) : null,
    [
      wizardOptions,
      scrollablePaneRef,
      readonly,
      data.schema,
      data.page,
      customFormButtons,
      fullscreen,
      layoutContext?.isHorizontalMenu,
      editFormsAsJson,
      scrollablePaneStyles,
      errors,
      refreshSuggestionVisible,
      t,
      formMessages,
      isCopiedToClipboard,
      unsetCopied,
      itemInfoContextValue,
      renderBefore,
      isOpened,
      resolvedSchema,
      initialFormData,
      onBeforeSubmit,
      onSubmit,
      handleSubmitAs,
      closeForm,
      submitDisabled,
      onChangeHandler,
      resetTimestamp,
      getCurrentFormState,
      handleCopiedToClipboard,
      dissmissMessageHandler,
    ]
  );

  const dispatch = useDispatch();
  const toggleHideDescriptionsInForm = useCallback(() => {
    dispatch(applySettings({ settings: { hideDescriptionsInForm: !hideDescriptionsInForm } }));
  }, [dispatch, hideDescriptionsInForm]);

  const toggleEditFormsAsJson = useCallback(() => {
    dispatch(applySettings({ settings: { editFormsAsJson: !editFormsAsJson } }));
  }, [dispatch, editFormsAsJson]);

  const toggleExpertMode = useCallback(() => {
    dispatch(setExpertMode({ settings: { expertMode: !expertMode } }));
  }, [dispatch, expertMode]);

  const onDrawerHeaderRender = useCallback((): JSX.Element => {
    return (
      <div className={classNames(styles.header, { [styles.fullScreen]: fullscreen })}>
        <div>
          <Text variant="xLarge">{resolvedSchema?.title || data.schema?.title}</Text>
        </div>
        {!!baseLanguage.locale && !data.schema?.cp_disableLocalization && !readonly && (
          <InputLanguage baseLanguage={baseLanguage} currentLanguage={currentLanguage} readonly={readonly} type={type} />
        )}
      </div>
    );
  }, [fullscreen, resolvedSchema?.title, data.schema?.title, data.schema?.cp_disableLocalization, baseLanguage, currentLanguage, readonly, type]);

  const extraDrawerButtonsRender = useCallback(() => {
    if (readonly || !data.schema) {
      return null;
    }
    const buttons: JSX.Element[] = [];

    if (powerUser) {
      if (canUseJsonEditor) {
        buttons.push(
          <HoverTooltip key="json" content={t(!editFormsAsJson ? 'common.showJsonEditor' : 'common.hideJsonEditor')}>
            <IconButton
              onClick={toggleEditFormsAsJson}
              iconProps={{ iconName: 'FileCode' }}
              style={{ color: !editFormsAsJson ? 'rgb(165, 167, 165)' : undefined }}
              data-is-focusable={false}
            />
          </HoverTooltip>
        );
      }

      buttons.push(
        <HoverTooltip key="descriptions" content={t(hideDescriptionsInForm ? 'common.showDescriptions' : 'common.hideDescriptions')}>
          <IconButton
            onClick={toggleHideDescriptionsInForm}
            iconProps={{ iconName: 'Info' }}
            style={{ color: hideDescriptionsInForm ? 'rgb(165, 167, 165)' : undefined }}
            data-is-focusable={false}
          />
        </HoverTooltip>
      );

      if (isExpertFieldInside) {
        buttons.push(
          <HoverTooltip key="expertMode" content={t('common.expertMode')}>
            <IconButton
              key="expertMode"
              onClick={toggleExpertMode}
              iconProps={{ iconName: 'TriggerUser' }}
              style={{ color: !expertMode ? 'rgb(165, 167, 165)' : undefined }}
              data-is-focusable={false}
            />
          </HoverTooltip>
        );
      }
    }
    return buttons;
  }, [
    t,
    readonly,
    data.schema,
    toggleHideDescriptionsInForm,
    hideDescriptionsInForm,
    toggleEditFormsAsJson,
    canUseJsonEditor,
    editFormsAsJson,
    isExpertFieldInside,
    toggleExpertMode,
    expertMode,
    powerUser,
  ]);

  const onDrawerWheel = useCallback(
    (e: React.WheelEvent<PanelBase>) => {
      if (!fullscreen) return;
      let element = e.target as Element;
      let allowScroll = false;
      // Iterate over target and its parents to find element with id drawer
      while (true) {
        if (element.id === id) {
          allowScroll = true;
          break;
        }
        if (element.parentElement) {
          element = element.parentElement;
        } else break;
      }
      const contentContainer = scrollablePaneRef.current?.contentContainer;
      if (contentContainer && allowScroll) {
        contentContainer.scrollTop += e.deltaY;
      }
    },
    [fullscreen, id]
  );

  const fullScreenDrawerProps = {
    id: id,
    defaultMaximized: true,
    hideMaximizing: true,
    isBlocking: false,
    style: headerLogo
      ? ({
          '--url': `url(${headerLogo})`,
        } as CSSProperties)
      : {},
    className: classNames(styles.fullScreenDrawer, {
      [styles.horizontalMenu]: layoutContext?.isHorizontalMenu,
      [styles.dark]: darkMode,
      [styles.withImage]: wizardOptions?.isWizardMode,
    }),
    styles: {
      main: {
        backgroundColor: darkMode ? theme?.palette.white : theme?.palette.neutralLighterAlt,
      },
      contentInner: {
        '&::after': {
          // Add random background image here
        },
      },
    },
    wheelEventHandler: onDrawerWheel,
    // zIndex is changed in order to allow header callouts rendering
    layerProps: { eventBubblingEnabled: true, styles: { root: { zIndex: '999999 !important' } } },
  };

  return isCached ? null : (
    <Drawer
      isOpen={isOpened}
      onClose={onCloseAndClearErrors}
      onRenderHeader={onDrawerHeaderRender}
      extraButtonsRender={extraDrawerButtonsRender}
      {...(fullscreen
        ? fullScreenDrawerProps
        : {
            layerProps: {
              eventBubblingEnabled: true,
            },
          })}
    >
      {form}
      {readonlyContent}
    </Drawer>
  );
};

export default ItemInfo;
